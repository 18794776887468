import { Box, Flex, Text } from '@chakra-ui/react';
import { useShoppingCart } from 'context/shoppingCartContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { H3 } from '@/components/Heading';
import ProductImage from '@/components/Image/ProductImage';
import { ProductItemActions } from '@/components/ShoppingCart/ProductItemActions';
import RemoveItem from '@/components/ShoppingCart/RemoveItem';
import NavLink from '@/components/UI/Links/NavLink';

import { ProductProps } from '@/models/props/ProductCardProps';
import { ProductUtil } from '@/utils';
import { AppService } from '@/services';

export function ProductItem({ product, basketItem }: ProductProps) {
    const { code, netVolume, points, priceGrossSingle, priceGross, quantity } = basketItem;
    const intl = useIntl();
    const { cartItems, editable } = useShoppingCart();
    const [currQuantity, setCurrQuantity] = useState<number>(quantity);
    const productUrl = ProductUtil.getProductUrl(product);

    const obj = useMemo(() => ({ code, quantity, product, intl }), [code, quantity, product, intl]);

    const addToCartDataLayer = useCallback(() => {
        const currency = AppService.getInstanceCurrency();
        const brand = intl.formatMessage({
            id: `data-layer.brand.${process.env.NEXT_PUBLIC_SELL_ZONE}`
        });
        const { categories, code, grossPrice, grossPriceBeforeDiscount, title } = product;
        const numberGrossPrice = Number(grossPrice?.replace(',', '.'));
        const numberGrossPriceBeforeDiscount = Number(grossPriceBeforeDiscount?.replace(',', '.'));
        const discount = Math.round(numberGrossPriceBeforeDiscount * 100 - numberGrossPrice * 100);
        const category = categories?.[0].subs?.[0].name || '';

        window.dataLayer.push({ ecommerce: 'null' });
        window.dataLayer.push({
            event: 'Koszyk',
            ecommerce: {
                currency: currency,
                value: numberGrossPrice,
                items: [
                    {
                        item_id: code,
                        item_name: title,
                        index: 1,
                        discount: discount / 100,
                        item_brand: brand,
                        item_category: category,
                        price: numberGrossPrice,
                        quantity: 1
                    }
                ]
            }
        });
    }, [product, intl]);


    useEffect(() => {
        const inBasket = cartItems.find((i) => i.code === obj.code);

        if (inBasket) setCurrQuantity(obj.quantity);
    }, [cartItems, product, obj]);

    return (
        <>
            {product && (
                <Flex gap={3}>
                    <Box
                        position="relative"
                        borderRadius="base"
                        h={{ base: '92px', md: '97px' }}
                        w={32}
                    >
                        <Flex
                            justifyContent="center"
                            alignItems="center"
                            position="relative"
                            h="100%"
                            w="100%"
                        >
                            <ProductImage mainImage={product.mainImage} title={product.title} />
                        </Flex>
                    </Box>
                    <Box width="100%">
                        <Flex alignItems="center" pb={1} fontSize="sm" color="grey.800">
                            <Text>
                                <FormattedMessage id="product-code" />: {product.code}
                            </Text>
                            {points > 0 && (
                                <Text ml={4}>
                                    {points} <FormattedMessage id="pkt" />
                                </Text>
                            )}
                            <Box ml="auto">
                                <RemoveItem code={product.code} isDisabled={!editable} />
                            </Box>
                        </Flex>
                        <NavLink href={productUrl}>
                            <H3 size="h5" fontWeight="normal" mb={0}>
                                {obj.product.title}
                            </H3>
                        </NavLink>
                        <Box pt={3}>
                            {currQuantity !== undefined && (
                                <ProductItemActions
                                    quantity={currQuantity}
                                    code={code}
                                    maximalProductCountInOrder={30}
                                    netPrice={String(netVolume)}
                                    priceGross={priceGross}
                                    priceGrossSingle={priceGrossSingle}
                                    currency={product.currency}
                                    incraseProductCountCallback={addToCartDataLayer}
                                />
                            )}
                        </Box>
                    </Box>
                </Flex>
            )}
        </>
    );
}
