import { Box, Flex, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { FormattedPrice } from '@/components/FormattedPrice/FormattedPrice';
import PresentQuantityHandler from '@/components/ShoppingCart/PresentQuantityHandler';
import ProductQuantityHandler from '@/components/ShoppingCart/ProductQuantityHandler';

import { Product } from '@/models/api';
import { ErrorCode } from '@/models/api/Error';
import { DisabledReason } from '@/models/props/ProductCardProps';
import { AppService } from '@/services';

import { useShoppingCart } from '../../../context/shoppingCartContext';

export function ProductItemActions(
    props: Pick<
        Product,
        | 'code'
        | 'maximalProductCountInOrder'
        | 'priceGross'
        | 'priceGrossSingle'
        | 'netPrice'
        | 'currency'
        | 'priceGrossWas'
    > & {
        quantity: number;
        dir?: 'column' | 'row';
        justify?: 'space-between' | 'start';
        stock?: number;
        promotionCode?: string;
        incraseProductCountCallback?: (quantity: number) => void;
    }
) {
    const {
        code,
        maximalProductCountInOrder,
        priceGross,
        priceGrossSingle,
        priceGrossWas,
        quantity,
        dir,
        justify,
        stock,
        promotionCode,
        incraseProductCountCallback
    } = props;
    const currency = AppService.getInstanceCurrency();
    const { currentBasket, fetchingData } = useShoppingCart();
    const [currQuantity, setCurrQuantity] = useState<number>(quantity);
    const { controls } = currentBasket || {};

    useEffect(() => {
        currentBasket?.products.forEach((item) => {
            if (item.code === code && item.quantity !== currQuantity) {
                setCurrQuantity(() => item.quantity);
            }
        });
    }, [code, currQuantity, currentBasket]);

    return (
        <Flex
            direction={{ base: 'column', md: dir ?? 'column' }}
            justifyContent={justify ?? 'start'}
            columnGap={4}
            w="100%"
        >
            <Flex alignItems="center">
                {controls?.editable ? (
                    <>
                        <Box width="min-content" minW="140px" flexGrow={0} flexShrink={0}>
                            {promotionCode ? (
                                <PresentQuantityHandler
                                    code={code}
                                    quantity={currQuantity}
                                    maxOrder={maximalProductCountInOrder}
                                    variant="sm"
                                    promotionCode={promotionCode}
                                    stock={stock}
                                    incrasePresentCountCallback={(quantity: number) => {
                                        if (incraseProductCountCallback) {
                                            incraseProductCountCallback(quantity);
                                        }
                                    }}
                                />
                            ) : (
                                <ProductQuantityHandler
                                    code={code}
                                    quantity={currQuantity}
                                    maxOrder={maximalProductCountInOrder}
                                    variant="sm"
                                    fetchingData={fetchingData}
                                    incraseProductCountCallback={(quantity: number) => {
                                        if (incraseProductCountCallback) {
                                            incraseProductCountCallback(quantity);
                                        }
                                    }}
                                />
                            )}
                        </Box>
                        {priceGrossSingle && (
                            <Text as="span" ml="2">
                                &times;{' '}
                                <FormattedPrice value={priceGrossSingle} currency={currency} />
                            </Text>
                        )}
                    </>
                ) : (
                    controls?.disabledReason === DisabledReason.PendingTransferPayment && (
                        <Text color="red">
                            <FormattedMessage id={ErrorCode.disabled} />
                        </Text>
                    )
                )}
            </Flex>
            <Flex alignItems="center" lineHeight="none" pt={{ base: 2, md: dir === 'row' ? 0 : 2 }}>
                {priceGrossWas && (
                    <Text
                        textDecoration="line-through"
                        pb="2px"
                        color="grey.600"
                        mr="10px"
                        ml={{ base: 'auto', md: 0 }}
                    >
                        <FormattedPrice value={priceGrossWas} currency={currency} />
                    </Text>
                )}
                <Text
                    fontWeight="bold"
                    whiteSpace="nowrap"
                    ml={{ base: !priceGrossWas ? 'auto' : 0, md: 0 }}
                    fontSize={{ base: 'xl', md: '27px' }}
                >
                    <FormattedPrice value={priceGross} currency={currency} />
                </Text>
            </Flex>
        </Flex>
    );
}
